import { api } from '@/lib/api';

import { BettingPlaceStatus } from './bettingPlace.types';

const getAllBettingPlaces = () => {
  return api.get('/user/bo/get-all-location');
};

const togglePlaceVisibility = (payload: BettingPlaceStatus) => {
  return api.post('/user/bo/change-visibility', {
    ...payload,
  });
};

export const getAllCities = () => {
  return api.get('/user/bo/all-cities');
};

export const bettingPlaceRepo = {
  getAllBettingPlaces,
  togglePlaceVisibility,
  getAllCities,
};
