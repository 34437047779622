
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';

import { EventDetails } from '@/ui/components/EventDetails';
import { PrematchSetup } from '@/ui/components/PrematchSetup';
import { EventNotes } from '@/ui/components/EventNotes';
import { EventTemplates } from '@/ui/components/EventTemplates';
import { EventStore } from '@/modules/event';
import { EventActions } from '@/ui/components/EventActions';
import { BlockedByEvent } from '@/ui/components/blockedByEvent';

@Component({
  components: {
    EventDetails,
    PrematchSetup,
    EventNotes,
    EventTemplates,
    EventActions,
    BlockedByEvent,
  },
})
export default class PrematchEventDetails extends Vue {
  @Prop({ required: false })
  showTemplatesTab!: boolean;

  get selectedEvent() {
    return EventStore.active;
  }
}
