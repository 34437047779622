
import { EventStore } from '@/modules/event/event.store';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import BlockedBettingPlaces from './BlockedBettingPlaces.vue';
import BlockedUsersList from './BlockedUsersList.vue';

@Component({ name: 'BlockedByEvent', components: { BlockedBettingPlaces, BlockedUsersList } })
export default class BlockedByEvent extends Vue {
  beforeDestroy() {
    EventStore.clearStateBlockedByEvent();
  }
}
