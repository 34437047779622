
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import to from 'await-to-js';

import { PlatformStore } from '@/modules/platform';
import { InputConfirm } from '@/ui/components/InputConfirm';

@Component({ components: { InputConfirm } })
export default class SuspendOfferButton extends Vue {
  isLoading = false;

  @Prop({ required: true })
  offerType!: 'Prematch' | 'Live';

  get buttonLabel() {
    return this.isSuspended
      ? this.$t(`common.unsuspend${this.offerType}`)
      : this.$t(`common.suspend${this.offerType}`);
  }

  get confirmText() {
    return this.isSuspended
      ? this.$t(`common.start${this.offerType}Confirm`)
      : this.$t(`common.stop${this.offerType}Confirm`);
  }

  get isSuspended() {
    return this.offerType === 'Prematch'
      ? PlatformStore.isPrematchSuspended
      : PlatformStore.isLiveSuspended;
  }

  get buttonIcon() {
    return this.isSuspended ? 'play-circle' : 'pause-circle';
  }

  get buttonType() {
    return this.isSuspended ? 'danger' : 'primary';
  }

  get getSuspendState() {
    return this.offerType === 'Prematch'
      ? PlatformStore.getIsPrematchSuspended
      : PlatformStore.getIsLiveSuspended;
  }

  get toggleOffer() {
    return this.offerType === 'Prematch'
      ? PlatformStore.togglePrematchOffer
      : PlatformStore.toggleSuspendState;
  }

  async mounted() {
    this.isLoading = true;
    const [_err] = await to(this.getSuspendState());
    this.isLoading = false;
  }

  async toggleSuspendState() {
    this.isLoading = true;
    const [_err] = await to(this.toggleOffer());
    this.isLoading = false;
  }
}
