
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import debounce from 'lodash/debounce';
import to from 'await-to-js';

import { UserStore } from '@/modules/userManagement/user.store';

@Component
export default class SearchUser extends Vue {
  users: any[] = [];
  loading = false;
  onSearch = debounce(this.handleSearch, 400);
  showDropdown = false;
  inputValue = '';
  isInputActive = false;

  @Prop()
  aditionalFilters!: object;

  @Prop({ default: 'Select' })
  buttonText!: string;

  @Prop({ default: 'Not Found' })
  notFoundContent!: string;

  @Prop({ default: 'Search User' })
  placeholder!: string;

  @Watch('inputValue')
  inputValueHandler(val: string) {
    if (!val.length && !this.isInputActive) {
      this.toggleDropdown(false);
    }
  }

  async handleSearch(value: string) {
    if (!value || value === '' || value.length < 3) {
      this.users = [];
      this.inputValue = value;
      return;
    }
    this.inputValue = value;

    const searchParams = {
      limit: 50,
      offset: 0,
      username: value,
      ...(this.aditionalFilters || {}),
    };

    this.loading = true;
    const [err, res] = await to<any>(UserStore.fetchUsers(searchParams));
    this.loading = false;
    if (err) return;
    if (!res) return;

    this.users = res.items.map((user: any) => ({
      value: user.id,
      label: user.username,
    }));
  }

  userAction({ value, label }: { value: number; label: string }) {
    this.$emit('userAction', {
      username: label,
      userId: value,
    });
    this.toggleDropdown(false);
    this.inputValue = '';
    this.users = [];
  }

  toggleDropdown(value: boolean) {
    this.showDropdown = value;
  }

  onBlur() {
    this.toggleDropdown(false);
  }

  onFocus() {
    this.isInputActive = true;
    this.toggleDropdown(true);
  }
}
