
import Vue from 'vue';
import Component from 'vue-class-component';
import { ICellRendererParams } from '@ag-grid-community/core';

interface ButtonCellParams extends ICellRendererParams {
  onClick: (data: any) => any;
  buttonText: string;
  buttonType: 'primary' | 'danger';
  showPopconfirm?: boolean;
}

@Component
export default class ButtonCell extends Vue {
  params: ButtonCellParams | undefined;

  get hasData() {
    return !!this.params?.data;
  }

  get shouldShowConfirm() {
    return !!this.params?.showPopconfirm;
  }

  onClickHandler(event: any) {
    if (event) {
      event.stopPropagation();
    }
    this.params?.onClick(this.params.data);
  }
}
