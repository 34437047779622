
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';

import { OutcomesByTemplate } from '@/modules/event';

@Component({
  name: 'OutcomeOddEntry',
})
export default class OutcomeOddEntry extends Vue {
  @Prop({ required: true })
  odd!: OutcomesByTemplate;

  @Prop({ required: true })
  eventId!: string;

  tempValue = null;
  tempLimit = null;

  onOddChange(value: any) {
    this.tempValue = value;
    this.$emit('change', [this.tempLimit, this.tempValue, this.odd.outcome_id]);
  }

  onLimitChange(value: any) {
    this.tempLimit = value;
    this.$emit('change', [this.tempLimit, this.tempValue, this.odd.outcome_id]);
  }
}
