import { bettingPlaceRepo } from './bettingPlace.repo';
import { BettingPlaceStatus, BettingPlaceData } from './bettingPlace.types';

class BettingPlaceService {
  getAllBettingPlaces() {
    return bettingPlaceRepo.getAllBettingPlaces();
  }

  togglePlaceVisibility(payload: BettingPlaceStatus) {
    return bettingPlaceRepo.togglePlaceVisibility(payload);
  }

  getAllCities() {
    return bettingPlaceRepo.getAllCities();
  }

  mappedBettingPlaces(bettingPLaces: BettingPlaceData[]) {
    return bettingPLaces.map(bettingPlace => {
      return { label: bettingPlace.address, value: bettingPlace.betting_place_id };
    });
  }
}

export const bettingPlaceService = new BettingPlaceService();
