
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { BettingPlaceStore } from '@/modules/bettingPlace/bettingPlace.store';
import { bettingPlaceService } from '@/modules/bettingPlace/bettingPlace.service';

@Component
export default class SearchBettingPlace extends Vue {
  loading = false;
  showDropdown = false;
  inputValue = '';
  isInputActive = false;

  @Prop({ default: 'Select' })
  buttonText!: string;

  @Prop({ default: 'Not Found' })
  notFoundContent!: string;

  @Prop({ default: 'Search User' })
  placeholder!: string;

  get bettingPlaces() {
    return bettingPlaceService.mappedBettingPlaces(BettingPlaceStore.bettingPlaces);
  }

  @Watch('inputValue')
  inputValueHandler(val: string) {
    if (!val.length && !this.isInputActive) {
      this.toggleDropdown(false);
    }
  }

  bettingPlaceAction({ value, label }: { value: number; label: string }) {
    this.$emit('bettingPlaceAction', {
      betting_place_name: label,
      betting_place_id: value,
    });
    this.toggleDropdown(false);
  }

  toggleDropdown(value: boolean) {
    this.showDropdown = value;
  }

  onBlur() {
    this.isInputActive = false;
    this.toggleDropdown(false);
  }

  onFocus() {
    this.isInputActive = true;
    this.toggleDropdown(true);
  }
}
