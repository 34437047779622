
import { Mixins, Component } from 'vue-property-decorator';

import { FilterConfig } from '@/modules/filter/filter.types';
import { WithEvents } from '@/ui/mixins/WithEvents';
import { EventStore, eventService, EventState, FactoryEvent } from '@/modules/event/';
import PrematchEventDetails from './components/PrematchEventDetails.vue';
import CompareOddsModal from '@/ui/pages/event-managment/components/CompareOddsModal.vue';
import SuspendOfferButton from '../live/components/SuspendOfferButton.vue';
import { HAS_BETRADAR_IDS_SELECT_OPTIONS } from '@/modules/filter/filter.constants';
import { WithGrpcEvents } from '@/ui/mixins/WithGrpcEvents';
import ManualOddEntryModal from '@/ui/pages/event-managment/components/ManualOddEntryModal.vue';

@Component({
  components: { PrematchEventDetails, CompareOddsModal, SuspendOfferButton, ManualOddEntryModal },
})
export default class PrematchPage extends Mixins(WithEvents, WithGrpcEvents) {
  filterConfig: FilterConfig = [
    { label: this.$t('event.name'), type: 'input-filter', key: 'name' },
    {
      label: this.$t('event.state'),
      type: 'event-state-filter',
      key: 'state',
      props: { visibleStates: ['PUBLISHED', 'HIDDEN', 'DISABLED'] },
    },
    {
      label: this.$t('event.hasBetRadar'),
      type: 'select-filter',
      key: 'has_betradar_id',
      props: { selectOptions: HAS_BETRADAR_IDS_SELECT_OPTIONS },
    },
    {
      label: this.$t('common.start'),
      type: 'range-filter',
      key: ['startDate', 'endDate'],
      props: { showTime: true },
    },
  ];

  get tableProps() {
    return {
      columnDefs: [
        {
          headerName: this.$t('event.name'),
          field: 'name',
          width: 300,
          checkboxSelection: true,
          cellRendererFramework: 'FullEventName',
          cellRendererParams: {
            showSuspend: true,
            cellType: 'PREMATCH',
            hasPlayers: 'hasPublishedPlayers',
          },
        },
        {
          headerName: this.$t('event.code'),
          width: 100,
          cellRendererFramework: 'EventCode',
        },
        {
          headerName: this.$t('common.template'),
          width: 235,
          cellRendererFramework: 'EventTemplates',
        },
        {
          width: 180,
          cellRendererFramework: 'EventActions',
        },
        {
          cellRendererFramework: 'EventMarkets',
          flex: 1,
        },
        {
          cellRendererFramework: 'ViewButtonWithOdds',
          width: 85,
          pinned: 'right',
          cellRendererParams: {
            cellType: 'PREMATCH',
          },
        },
      ],
      rowMultiSelectWithClick: true,
      rowSelection: 'multiple',
      rowData: this.tableData,
      onRowSelect: this.onRowSelect,
      pagination: true,
      paginationPageSize: 10,
    };
  }

  get gridOptions() {
    return {
      rowHeight: 96,
      getRowClass: ({ data }: { data: FactoryEvent }) => {
        const event = EventStore.events[eventService.formatEventId(data.intKey)];
        if (!event) return;

        return eventService.getEventClass(event);
      },
    };
  }

  get excludeStates(): EventState[] {
    return ['VALIDATED'];
  }

  setSource() {
    EventStore.setRequestSource('PREMATCH');
  }

  async fetchData() {
    this.gridApi?.showLoadingOverlay();
    await EventStore.getPrematch();
  }
}
