
import { Component, Mixins } from 'vue-property-decorator';
import to from 'await-to-js';

import { WithDataTable } from '@/ui/mixins/WithDataTable';
import FavoriteToggle from '@/ui/components/CellRenderers/FavoriteToggle.vue';
import { WithNetworkStatus, NetworkStatus } from '@/ui/mixins/WithNetworkStatus';
import { EventStore } from '@/modules/event';
import { SearchUserDropdown } from '@/ui/components/searchUserDropdown';
import ButtonCell from '@/ui/components/CellRenderers/ButtonCell.vue';

@Component({
  name: 'BettingPlacesList',
  components: { FavoriteToggle, SearchUserDropdown, ButtonCell },
})
export default class UsersList extends Mixins(WithDataTable, WithNetworkStatus) {
  get tableProps() {
    return {
      columnDefs: [
        { headerName: this.$t('user.username'), field: 'username', width: 300 },

        {
          headerName: this.$t('event.unblockUser'),
          cellRendererFramework: 'ButtonCell',
          cellRendererParams: {
            onClick: this.unblockUserOnEvent,
            buttonText: this.$t('user.unblock'),
            buttonType: 'success',
            showPopconfirm: true,
          },
          flex: 1,
        },
      ],
      rowData: this.tableData,
    };
  }

  get isInfinite() {
    return false;
  }

  get tableData() {
    return EventStore.blockedUsersOnEvent;
  }

  async unblockUserOnEvent({ userId }: { userId: number }) {
    this.setNetworkStatus(NetworkStatus.InProgress);
    const [err] = await to<any>(
      EventStore.unblockUserOnEvent({
        user_id: userId,
        event_int_key: EventStore.active?.intKey,
      })
    );
    if (err) return this.setNetworkStatus(NetworkStatus.Error);
    this.setNetworkStatus(NetworkStatus.Success);
    EventStore.removeBlockedUser(userId);
    this.successMessage(this.$t('event.unblockedOnUser') as string);
  }

  successMessage(message: string) {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: message,
    });
  }

  async blockUserOnEvent({ userId, username }: { userId: number; username: string }) {
    if (this.isLoading) return;
    this.setNetworkStatus(NetworkStatus.InProgress);
    const [err] = await to<any>(
      EventStore.blockUserOnEvent({
        user_id: userId,
        username,
        event_int_key: EventStore.active?.intKey,
      })
    );
    if (err) return this.setNetworkStatus(NetworkStatus.Error);
    this.setNetworkStatus(NetworkStatus.Success);
    EventStore.setBlockedUsers([
      {
        userId,
        username,
      },
    ]);
    this.successMessage(this.$t('event.blockedOnUser') as string);
  }

  mounted() {
    setTimeout(() => {
      this.gridApi?.hideOverlay();
    }, 0);
  }
}
