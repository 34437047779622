import { Action, getModule, Mutation, VuexModule, Module } from 'vuex-module-decorators';
import to from 'await-to-js';
import find from 'lodash/find';
import filter from 'lodash/filter';

import store from '@/store';
import { bettingPlaceService } from './bettingPlace.service';
import { BettingPlaceData, BettingPlaceStatus, SelectedCity } from './bettingPlace.types';

@Module({ dynamic: true, store, name: 'bettingPlace', namespaced: true })
class BettingPlace extends VuexModule {
  bettingPlaces: BettingPlaceData[] = [];
  filteredBettingPlaces: BettingPlaceData[] = [];
  totalBettingPlaces = 0;
  allLocations: SelectedCity[] = [];

  @Mutation
  setBettingPlaces(payload: BettingPlaceData[]) {
    this.bettingPlaces = payload;
    this.filteredBettingPlaces = payload;
    this.totalBettingPlaces = payload.length;
  }

  @Mutation
  setBettingPlaceStatus(payload: BettingPlaceStatus) {
    const foundLocation = find(
      this.bettingPlaces,
      bettingPlace => payload.betting_place_id === bettingPlace.betting_place_id
    );
    if (foundLocation) {
      foundLocation.is_active = payload.is_active;
    }
  }

  @Mutation
  filterLocations(payload: { label: string; value: string }) {
    if (!payload) {
      this.filteredBettingPlaces = this.bettingPlaces;
      this.totalBettingPlaces = this.filteredBettingPlaces.length;
      return;
    }
    this.filteredBettingPlaces = filter(
      this.bettingPlaces,
      bettingPlace => bettingPlace.city === payload.label
    );
    this.totalBettingPlaces = this.filteredBettingPlaces.length;
  }

  @Mutation
  setAllCities(payload: SelectedCity[]) {
    const uniqueLocations: SelectedCity[] = [];
    payload.forEach(cityData => {
      if (!uniqueLocations.some(location => location.city === cityData.city))
        uniqueLocations.push(cityData);
    });
    this.allLocations = uniqueLocations;
  }

  @Action
  async getAllBettingPlaces() {
    const [err, res] = await to<any>(bettingPlaceService.getAllBettingPlaces());
    if (err) return;
    this.setBettingPlaces(res);
  }

  @Action
  async togglePlaceVisibility(payload: BettingPlaceStatus) {
    const [err] = await to<any>(bettingPlaceService.togglePlaceVisibility(payload));
    if (err) return Promise.reject(err);
    this.setBettingPlaceStatus(payload);
  }

  @Action
  async getAllCities() {
    const [err, res] = await to<any>(bettingPlaceService.getAllCities());
    if (err) return;
    this.setAllCities(res);
  }
}

export const BettingPlaceStore = getModule(BettingPlace);
