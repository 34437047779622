
import { Component, Mixins } from 'vue-property-decorator';

import { EventStore } from '@/modules/event';
import { BettingPlaceStore } from '@/modules/bettingPlace/bettingPlace.store';
import { WithDataTable } from '@/ui/mixins/WithDataTable';
import ButtonCell from '@/ui/components/CellRenderers/ButtonCell.vue';
import { SearchBettingPlace } from '@/ui/components/searchBettingPlace';
import { WithNetworkStatus, NetworkStatus } from '@/ui/mixins/WithNetworkStatus';
import to from 'await-to-js';

@Component({ name: 'BettingPlacesList', components: { ButtonCell, SearchBettingPlace } })
export default class BettingPlacesList extends Mixins(WithDataTable, WithNetworkStatus) {
  get tableProps() {
    return {
      columnDefs: [
        {
          headerName: this.$t('bettingPlace.address'),
          field: 'address',
          width: 300,
        },
        {
          headerName: this.$t('event.unblockBettingPlace'),
          cellRendererFramework: 'ButtonCell',
          cellRendererParams: {
            onClick: this.unblockBettingPlace,
            buttonText: this.$t('user.unblock'),
            buttonType: 'success',
            showPopconfirm: true,
          },
          flex: 1,
        },
      ],
      rowData: this.tableData,
    };
  }

  get isInfinite() {
    return false;
  }

  get tableData() {
    return EventStore.blockedBettingPlacesOnEvent;
  }

  successMessage(message: string) {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: message,
    });
  }

  async blockBettingPlace({
    betting_place_id,
    betting_place_name,
  }: {
    betting_place_id: number;
    betting_place_name: string;
  }) {
    if (this.isLoading) return;
    this.setNetworkStatus(NetworkStatus.InProgress);
    const [err] = await to<any>(
      EventStore.blockBettingPlace({
        betting_place_id,
        address: betting_place_name,
        event_int_key: EventStore.active?.intKey,
      })
    );
    if (err) return this.setNetworkStatus(NetworkStatus.Error);
    this.setNetworkStatus(NetworkStatus.Success);
    EventStore.setBlockedBettingPlaces([
      {
        address: betting_place_name,
        bettingPlaceId: betting_place_id,
      },
    ]);
    this.successMessage(this.$t('event.blockedOnSelectedBettingPlace') as string);
  }

  async unblockBettingPlace({ bettingPlaceId }: { bettingPlaceId: number }) {
    this.setNetworkStatus(NetworkStatus.InProgress);
    const [err] = await to<any>(
      EventStore.unblockBettingPlace({
        betting_place_id: bettingPlaceId,
        event_int_key: EventStore.active?.intKey,
      })
    );
    if (err) return this.setNetworkStatus(NetworkStatus.Error);
    EventStore.removeBettingPlace(bettingPlaceId);
    this.successMessage(this.$t('event.unblockedOnBettingPlace') as string);
  }

  async mounted() {
    await BettingPlaceStore.getAllBettingPlaces();
    setTimeout(() => {
      this.gridApi?.hideOverlay();
    }, 0);
  }
}
