
import Vue from 'vue';
import { Prop, Component, Watch } from 'vue-property-decorator';
import { MarketStore } from '@/modules/market/market.store';
import { EventStore } from '@/modules/event/event.store';
import { eventService } from '@/modules/event/event.service';
import MarketsWithOdds from './MarketsWithOdds.vue';
import to from 'await-to-js';
import { InputParams } from '@/ui/components/InputParams';
import { InputParamsMarket } from '@/ui/components/InputParamsMarket';

import { InputParamsTennis } from '@/ui/components/InputParamsTennis';
import { SportStore } from '@/modules/sport';
import { oddToInt } from '@/lib/oddToInt';
import { MarketsByTemplateResponse } from '@/modules/event';
import OutcomeOddEntry from '@/ui/components/OutcomeOddEntry/OutcomeOddEntry.vue';

const modalBodyStyle = {
  height: '60vh',
  overflow: 'overlay',
};

@Component({
  components: {
    MarketsWithOdds,
    InputParams,
    InputParamsMarket,
    InputParamsTennis,
    OutcomeOddEntry,
  },
})
export default class ManualOddEntryModal extends Vue {
  bodyStyle = modalBodyStyle;
  oddsChanged = false;
  isSaving = false;
  outcomesMap = {};

  templateMarkets: MarketsByTemplateResponse = [];

  @Prop({ type: Boolean })
  visible!: boolean;

  @Prop({ type: Function })
  close!: () => void;

  @Prop({ type: Function })
  afterSave!: () => void;

  get selectedEvent() {
    return EventStore.active;
  }

  get tempOddValues() {
    return MarketStore.tempOddValues;
  }

  get sport() {
    if (!this.selectedEvent) return null;
    return SportStore.getSportById(this.selectedEvent.sportId);
  }

  get selectedId() {
    if (!this.selectedEvent) return '';
    return this.selectedEvent.intKey;
  }

  errorMessage(description = this.$t('common.somethingWentWrong')) {
    this.$notification.warning({
      message: this.$t('common.error') as string,
      description: description as string,
    });
  }

  successMessage() {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('inputParams.oddChangesAccepted') as string,
    });
  }

  afterOddsConfirm() {
    this.oddsChanged = true;
    this.outcomesMap = {};
    this.successMessage();
    this.close();
    this.afterSave && this.afterSave();
  }

  handleClose() {
    this.close();
  }

  afterModalClose() {
    if (!this.oddsChanged) return;
    if (!this.selectedEvent) return;
    MarketStore.resetMarkets();
    MarketStore.getMarkets(this.selectedEvent.intKey);
  }

  onEsc() {
    // param is needed e: any
    return;
  }

  setTemplateMarketsAndOdds(marketsArray: any) {
    this.templateMarkets = marketsArray.reduce((marketAcc: any, market: any) => {
      const foundMarket = MarketStore.markets?.find(mar => mar.id === market.market_id);
      if (foundMarket) {
        const mergedOdds = market.outcomes.reduce((acc: any, odd: any) => {
          const foundOdd = Object.values(MarketStore.odds as object).find(
            o => o.outcome_id === odd.outcome_id
          );
          if (foundOdd) {
            this.handleUpdateOdds([
              foundOdd.limit ? foundOdd.limit : null,
              foundOdd.prematch_prod_odd,
              foundOdd.outcome_id,
            ]);
          }
          return [...acc, { ...odd, ...foundOdd }];
        }, []);
        return [
          ...marketAcc,
          {
            ...market,
            outcomes: mergedOdds,
          },
        ];
      }
    }, []);
  }

  async fetchOutcomesByTemmplate() {
    if (!this.selectedEvent) return;
    const [err, res] = await to(eventService.getOutcomesByTemplate(this.selectedEvent.intKey));
    if (err || !res) return;
    this.setTemplateMarketsAndOdds(res);
  }

  @Watch('visible')
  onOpen(isVisible: boolean) {
    if (isVisible) {
      this.fetchOutcomesByTemmplate();
    }
  }

  handleUpdateOdds(args: any[]) {
    this.outcomesMap = {
      ...this.outcomesMap,
      [args[2]]: {
        //@ts-ignore
        prod_odd: oddToInt(args[1]) ? oddToInt(args[1]) : this.outcomesMap[args[2]]?.prod_odd,
        real_odd: null,
        limit: args[0],
      },
    };
  }

  async saveOdds() {
    if (!this.selectedId) return;
    const payload = {
      input_parameters: {},
      odds: this.outcomesMap,
      model: 1,
    };
    const [err] = await to(eventService.confirmOddsChange(this.selectedId, payload));
    if (err) return;
    this.afterOddsConfirm();
  }
}
